//JQuery Module Pattern
jQuery(function($) { 
  // An object literal
  var app = {
  init: function() {
    app.searchHeader();
    app.Headeroom();
    app.slidetemoin();
    app.fileStyle();
    app.rgpd();
    app.menuHover();
    app.toTop();
    app.biggerImg();
  },
  searchHeader: function() {
    //=> Search Header
    $(".formSearch > .container").append(
        '<span class="icon-close hide-sm"></span>'
    );
    $(".search-head .loupe").on("click", function (e) {
        e.preventDefault();
        $(".search-head").addClass("open");
        $("header").addClass("superIndex");
        setTimeout(function(){ 
        $(".searchText").focus();
      }, 1000);
    });
    $(".formSearch .icon-close").on("click", function (e) {
        e.preventDefault();
        $(".search-head").removeClass("open");
        $("header").removeClass("superIndex");
    });

    $(".search-form, .formSearchTop").on("submit", function() {
        $(this).find('.error').remove();
        var searchText = $.trim($(this).find('input[name=s]').val());
        if (searchText.length < 3) {
            $(this).append('<span class="error"> Vous devez saisir au minimum 3 caractères </span>');
            $('.searchText', this).addClass('inputerror');
            $(this).find('input[name=s]').eq(0).focus();
            return false;
        } else {                    
            $('.searchText', this).removeClass('inputerror');
        }
    });

    $(".search-form .searchText, .formSearchTop .searchText").on("keyup change", function() {
        var searchText = $.trim($(this).val());
        if (searchText.length > 1) {
            $(this).removeClass('inputerror');
            $('.search-form, .formSearchTop').find('.error').remove();
        }
    });
  },

  Headeroom : function() {
    // if ($(window).width() > 767) {
      if( $('header').length > 0 ){
        var options = {
            // vertical offset in px before element is first unpinned
            offset: 0,
            // scroll tolerance in px before state changes
            tolerance: 5,
            // css classes to apply
            classes: {
                // when element is initialised
                initial: "headroom",
                // when scrolling up
                pinned: "is-sticky",
                // when scrolling down
                unpinned: "not-sticky",
                // when above offset
                top: "headroom--top",
                // when below offset
                notTop: "headroom--not-top",
                // when at bottom of scoll area
                bottom: "headroom--bottom",
                // when not at bottom of scroll area
                notBottom: "headroom--not-bottom"
            }
        }
        // Element target
        var myElement = document.querySelector("header");
        var headroom  = new Headroom(myElement);

        // initialise settings
        headroom.offset = 100;//px

        headroom.init(options);
      }
    // }
  },

  slidetemoin : function() {
    $('.slidetemoin').slick({
      arrows: false,
      dots: false,
      slidesToShow: 1,
      autoplay: true
    });
  },
  fileStyle : function() {
    $('.fileInput').jfilestyle({
    
      // button text
      'text': 'Attacher une pièce jointe',
    
      // shows input field
      'input': true,
    
      // is disabled?
      'disabled': false,
    
      // where to place the button
      'buttonBefore': true,
    
      // input size
      'inputSize': 'auto',
    
      // custom placeholder
      'placeholder': 'Aucune pièce jointe',
    
      // enable drag and drop
      'dragdrop': true,
    
      // callback
      'onChange': function () {}
    
    })
  },
  rgpd : function() {
    if(typeof(readmoreLink) == 'undefined'){
      var readmoreLink = '';
    }

    tarteaucitron.init({
      "privacyUrl": readmoreLink,
      "hashtag": "#tarteaucitron",
      "cookieName": "rgpd",
      "orientation": "bottom",
      "showAlertSmall": false,
      "cookieslist": true,
      "adblocker": false,
      "AcceptAllCta" : true,
      "highPrivacy": true,
      "handleBrowserDNTRequest": false,
      "removeCredit": true, 
      "moreInfoLink": true, 
      "useExternalCss": true,           
      "readmoreLink": readmoreLink
    });

    if(typeof(analyticsCode) != 'undefined'){
      aucitron.user.analyticsUa = analyticsCode;
        //(tarteaucitron.job = tarteaucitron.job || []).push('analytics');
    }
      (tarteaucitron.job = tarteaucitron.job || []).push('analytics');
  },
  menuHover : function() {
    $('#menu-main-menu li.dropdown').hover(function() {
      $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
    }, function() {
      $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
    });
  },
  toTop : function(){
    $("#toTop").click(function () {
      $("html, body").animate({scrollTop: 0}, 1000);
   });
  },
  biggerImg : function(){
    $('[id="imgBigfroid"]').click(function () {
      $('.biggerImg img').attr("src", '/wp-content/uploads/2020/03/reseau-froid-min.jpg');
      $('.biggerImg').addClass("shown");
    });
    $('[id="imgBigchaud"]').click(function () {
      $('.biggerImg img').attr("src", '/wp-content/uploads/2020/03/reseau-chaud-min.jpg');
      $('.biggerImg').addClass("shown");
    });
    $('.biggerImg .icon-close').click(function () {
      $('.biggerImg').removeClass("shown");
      $('.biggerImg img').attr("src", "");
    })
  },
  };
  $(document).ready(function () {
    app.init();
  });
});